<script>
  import formatRelative from "date-fns/formatRelative";
  export let review;
</script>

<style>
  img {
    border-radius: 50%;
  }
  .review {
    width: 100%;
    text-align: start;
    flex-direction: column;
  }

  .stars {
    max-width: 100px;
    display: flex;
  }
</style>

<div class="review media">
  <img src={review.user_profile_picture} class="mr-3" alt="user" />
  <div class="media-body">
    <h5 class="mt-0">{review.title}</h5>
    <div class="stars">
      {#each [1, 2, 3, 4, 5] as star}
        {#if review.rating >= star}
          <i class="fas fa-star text-warning" />
        {/if}
      {/each}
    </div>
    <p>{review.description}</p>
  </div>
</div>
