<script>
  export let images = [];
  let chosenImage = images.find(x => x.main);

  function selectImage(image) {
    chosenImage = image;
  }
</script>

<style>
  .images {
    width: 100%;
    max-width: 768px;
    position: relative;
  }

  .image {
    width: inherit;
  }

  .picker {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    display: grid;
    color: gray;
    justify-content: center;
  }

  .choices {
    display: grid;
    grid-auto-flow: column;
    width: max-content;
    grid-gap: var(--medium);
    cursor: pointer;
  }

  .selected {
    color: white;
  }
</style>

<div class="images">
  <img class="image" src={chosenImage.url} />
  <div class="picker">
    {#if images.length > 1}
      <div class="choices">
        {#each images as image}
          <i
            class="fas fa-circle"
            class:selected={chosenImage === image}
            on:click={() => selectImage(image)} />
        {/each}
      </div>
    {/if}
  </div>
</div>
