<script>
  import { parse } from "query-string";
  import { getContext, onMount } from "svelte";
  import { navigate } from "svelte-routing";

  const instance = getContext("api");
  const parsedQuery = parse(location.search);
  const code = parsedQuery.code;

  onMount(async () => {
    // const res = await instance.get(`/login?code=${code}`);
    // console.log(res);
    // localStorage.setItem("access_token", res.data.access_token);
    // localStorage.setItem("picture", res.data.picture);
    // localStorage.setItem("name", res.data.name);
    // const thirtyDays = new Date(
    //   Date.now() + 1000 * 60 * 60 * 30 * 24
    // ).toUTCString();
    // document.cookie = `refresh_token=${res.data.refresh_token}; expires=${thirtyDays}; path=/`;
    navigate(`/`);
  });
</script>
