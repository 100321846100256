import {
    writable
} from 'svelte/store';
import * as querystring from "query-string";
const qsParams = querystring.parse(window.location.search);

export const instance = writable();
export const idToken = writable('');
export const accessToken = writable(localStorage.getItem("access_token"));
export const search = writable(qsParams.q || '');
export const category = writable(qsParams.c || '');
export const user = writable({});
export const userProfilePicture = writable(localStorage.getItem("profile_picture_url"))
