<script>
  import { Link } from "svelte-routing";
  export let product;
</script>

<style>
  .product {
    border-radius: 5px;
    border: 1px solid var(--light-color);
    max-height: 400px;
    overflow-y: hidden;
  }

  .product_text {
    text-decoration: none;
    color: black;
    padding: 5px;
  }

  .product_image {
    width: 100%;
    height: 200px;
  }

  .product_title {
    font-weight: bold;
    font-size: 1.25rem;
    text-decoration: none;
  }

  .product_description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .product_image {
    overflow: hidden;
    background-size: cover;
    background-position: center;
    height: 200px;
  }

  .product_link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .product_details {
    font-weight: 300;
    font-size: var(--medium);
  }

  .product_card {
    position: relative;
    max-height: 100%;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1),
      0 2px 10px 0 rgba(0, 0, 0, 0.25);
    border: none;
  }
</style>

<div class="product_card card">
  <Link to={`/product/${product.slug}`}>
    <div class="product_link" />
  </Link>
  <div
    style={`background-image:url('${((product.images || []).find(i => i.main) || {}).url}')`}
    class="product_image"
    alt="..." />
  <div class="text-left card-body">
    <h4 class="card-title">{product.name}</h4>
    <div class="product_details">
      <div class="card-text">{product.price}₫</div>
      <div class="card-text">
        {product.business_name}, {product.business_city}
      </div>
      {#if product.num_ratings > 0}
        <div>
          <b>
            <i class="fas fa-star text-warning" />
            {product.avg_rating}
          </b>
          <span>({product.num_ratings})</span>
        </div>
      {/if}
    </div>
  </div>
</div>
