<script>
  import AutoComplete from "./components/AutoComplete.svelte";
  import ImageUploader from "./components/ImageUploader.svelte";
  import BusinessHours from "./components/BusinessHours.svelte";
  import formatRelative from "date-fns/formatRelative";
  import axios from "axios";
  import { isEmpty, isEqual } from "lodash-es";
  import { NotificationDisplay, notifier } from "@beyonk/svelte-notifications";
  import cities from "./cities.json";
  import districts from "./districts.json";
  import { debounce } from "lodash-es";
  import { slide } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import { onMount } from "svelte";
  import { accessToken, user, instance } from "./stores.js";
  import { navigate, Link } from "svelte-routing";
  import { fly } from "svelte/transition";
  import ProductCard from "./ProductCard.svelte";
  import { translate } from "./translations/translate.js";
  import uuidv4 from "uuid/v4";
  import { uploadDataURLs } from "./image_util.js";
  import { vi } from "date-fns/locale";
  import * as yup from "yup";

  export let id;

  $: if (newBusinessDescription.length >= 500) {
    newBusinessDescription = newBusinessDescription.substring(0, 500);
  }

  const getDistricts = city => districts[city] || [];

  if (!$accessToken) {
    navigate("/signin");
  }
  onMount(async function() {
    try {
      const { data: businessData } = await $instance.put(
        `/owner/businesses/${id}`
      );
    } catch (err) {
      navigate("/my/business");
      return;
    }
    try {
      const { data: businessData } = await $instance.get(`/businesses/${id}`);
      if (businessData === null) {
        navigate("/my/business/new");
        return;
      }
      business = businessData;
      originalBusiness = { ...businessData };
      updatedAt = formatRelative(new Date(business.updated_at), new Date(), {
        locale: vi
      });
      try {
        const { data: productsData } = await $instance.get(
          `/products/list?id=${businessData.slug}`
        );
        products = productsData || [];
        if (business.logo) {
          currentLogo = [{ url: business.logo, main: true }];
        }
      } catch (err) {
        console.error(err);
      } finally {
        loadingProductList = false;
      }
    } catch (err) {
    } finally {
      loadingBusiness = false;
    }
  });

  let imageUploading = false;
  let unsavedChanges = false;
  let currentLogo = [];
  let saving = false;
  let n;
  function setCity(e) {
    business.city = e.detail;
  }
  function setDistrict(e) {
    business.district = e.detail;
  }

  let saved = false;
  let loadingBusiness = true;
  let searchLoading = false;
  let updatedAt = "";
  let loadingProductList = true;

  let newBusinessName = "";
  let newBusinessDescription = "";

  let businessLoadingError = "";
  let productsLoadingError = "";

  let business = {};
  let originalBusiness = {};
  let products = [];
  let addresses = [];
  let validationErrors = [];

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required()
      .max(100),
    description: yup.string().max(2000),
    address: yup.string().max(200),
    city: yup.string().max(200),
    district: yup.string().max(200),
    email: yup.string().email(),
    website: yup.string().max(1000),
    phone_number: yup.string().max(30)
  });

  async function createProduct(e) {
    navigate(`/my/business/${id}/products/new`);
  }

  async function uploadCb(result) {
    business.logo = URL.createObjectURL(result);
  }

  async function updateBusiness(e) {
    saving = true;
    e.preventDefault();
    validationErrors = [];
    try {
      await validationSchema.validate(business, {
        abortEarly: false
      });
    } catch (err) {
      validationErrors = err.inner;
      console.log(validationErrors[0]);
      saving = false;
      return;
    }

    try {
      if (
        currentLogo &&
        currentLogo[0] &&
        currentLogo[0].url.startsWith("data:")
      ) {
        const urls = await uploadDataURLs(
          $instance,
          [currentLogo[0]],
          "business_logos"
        );
        business.logo = urls[0].url;
        currentLogo.url = business.logo;
      }

      const { data } = await $instance.put(`/businesses/${id}`, business);

      updatedAt = formatRelative(new Date(data.updated_at), new Date(), {
        locale: vi
      });
      notifier.success(translate("saved"), 2000);
    } catch (err) {
      console.error(err);
      notifier.danger(translate("servererror"), 2000);
    } finally {
      saving = false;
    }
  }
</script>

<style>
  select {
    border: none;
    font-size: 1em;
    height: 35px;
  }
  .logo_wrapper {
    display: grid;
    grid-template-columns: 100px 100px;
    grid-gap: 15px;
  }

  .savePopup {
    position: fixed;
    width: 100vw;
    color: white;
    bottom: 0;
    z-index: 2;
    height: 60px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a > * {
    text-decoration: none;
    color: currentColor;
  }
  .product_image {
    height: 100px;
    width: 100px;
  }

  .product {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
    cursor: pointer;
  }

  .product_link {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .product:hover {
    background-color: var(--light-color);
  }

  .product_description {
    text-align: start;
    padding-left: 10px;
  }

  .product_description > * {
    color: black;
    text-decoration: none;
  }

  .product_description > p {
    font-weight: 300;
    font-size: var(--medium);
  }

  .save-business {
    margin-bottom: 20px;
    max-width: 320px;
  }

  .add-product {
    max-width: 320px;
    width: 100%;
    margin: 20px 0;
  }
  .products_container {
    margin-top: 3em;
  }
</style>

<NotificationDisplay bind:this={n} />
<div style="text-align:start">
  {#if saved}
    <div
      class="bg-success savePopup"
      transition:slide={{ delay: 250, duration: 300, easing: quintOut }}>
      Saved!
    </div>
  {/if}
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <Link to="/">{translate('homepage')}</Link>
      </li>
      <li class="breadcrumb-item" aria-current="page">
        {translate('mybusiness')}
      </li>
    </ol>
  </nav>
  {#if loadingBusiness}
    <div class="progress">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
        style="width: 100%" />
    </div>
  {:else if businessLoadingError}
    <div>{translate('servererror')}</div>
  {:else}
    <small>{translate('lastupdated')} {updatedAt}</small>
    <form style="text-align:start" on:submit={updateBusiness}>
      <div class="form-group">
        <label for="name">{translate('name')}</label>
        <input name="name" bind:value={business.name} class="form-control" />
        {#if validationErrors.some(e => e.path === 'name')}
          <div class="text-danger">
            {validationErrors.find(e => e.path === 'name').message}
          </div>
        {/if}
      </div>
      <div class="logo_wrapper">
        <div class="form-group">
          <label for="logo">
            {translate('logo')}
            <small>({translate('optional')})</small>
          </label>
          <ImageUploader
            aspectRatio={1}
            height={100}
            width={100}
            loading={imageUploading}
            completionCb={uploadCb}
            bind:images={currentLogo} />
        </div>
        {#if validationErrors.some(e => e.path === 'logo')}
          <div class="text-danger">
            {validationErrors.find(e => e.path === 'logo').message}
          </div>
        {/if}
      </div>
      <div class="form-group">
        <label for="description">{translate('description')}</label>
        <textarea
          name="description"
          bind:value={business.description}
          class="form-control" />
        <small class:text-danger={business.description.length > 2000}>
          ({business.description.length}/2000)
        </small>
        {#if validationErrors.some(e => e.path === 'description')}
          <div class="text-danger">
            {validationErrors.find(e => e.path === 'description').message}
          </div>
        {/if}
      </div>
      <div class="form-group">
        <label for="address">{translate('address')}</label>
        <input
          name="address"
          bind:value={business.address}
          class="form-control" />
        {#if validationErrors.some(e => e.path === 'address')}
          <div class="text-danger">
            {validationErrors.find(e => e.path === 'address').message}
          </div>
        {/if}
      </div>
      <div class="form-group">
        <label for="city">{translate('city')}</label>
        <AutoComplete
          search={business.city}
          value={business.city}
          name="city"
          items={cities}
          on:close={setCity}>
          <div class="notification">Loading data from API...</div>
        </AutoComplete>
        {#if validationErrors.some(e => e.path === 'city')}
          <div class="text-danger">
            {validationErrors.find(e => e.path === 'city').message}
          </div>
        {/if}
      </div>
      {#if !isEmpty(getDistricts(business.city))}
        <div class="form-group">
          <label for="district">{translate('district')}</label>
          <select bind:value={business.district}>
            {#each getDistricts(business.city).sort() as district}
              <option value={district}>{district}</option>
            {/each}
          </select>
          {#if validationErrors.some(e => e.path === 'district')}
            <div class="text-danger">
              {validationErrors.find(e => e.path === 'district').message}
            </div>
          {/if}
        </div>
      {/if}
      <div class="form-group">
        <label for="email">{translate('email')}</label>
        <input name="email" bind:value={business.email} class="form-control" />
        {#if validationErrors.some(e => e.path === 'email')}
          <div class="text-danger">
            {validationErrors.find(e => e.path === 'email').message}
          </div>
        {/if}
      </div>
      <div class="form-group">
        <label for="website">{translate('website')}</label>
        <input
          name="website"
          bind:value={business.website}
          class="form-control" />
        {#if validationErrors.some(e => e.path === 'website')}
          <div class="text-danger">
            {validationErrors.find(e => e.path === 'website').message}
          </div>
        {/if}
      </div>
      <div class="form-group">
        <label for="phone_number">{translate('phonenumber')}</label>
        <input
          name="phone_number"
          bind:value={business.phone_number}
          class="form-control" />
        {#if validationErrors.some(e => e.path === 'phone_number')}
          <div class="text-danger">
            {validationErrors.find(e => e.path === 'phone_number').message}
          </div>
        {/if}
      </div>
      <div class="form-group">
        <label for="hours">{translate('hours')}</label>
        <BusinessHours bind:hours={business.hours} />
      </div>
      {#if !isEqual(business, originalBusiness)}
        <button
          class="btn primary save-business"
          disabled={saving}
          type="submit">
          {#if saving}
            <i class="fas fa-spinner fa-spin" />
          {:else}{translate('save')}{/if}
        </button>
      {/if}
    </form>
    <div class="products_container">
      <h5>{translate('products')}</h5>
      {#if loadingProductList}
        <div class="progress">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: 75%" />
        </div>
      {:else if productsLoadingError}
        <div>An error loading products!</div>
      {:else if !products}
        <button class="btn primary add-product" on:click={createProduct}>
          {translate('newproduct')}
        </button>
        <div>{translate('noproducts')}</div>
      {:else}
        {#if products.length <= 15}
          <button class="btn primary add-product" on:click={createProduct}>
            {translate('newproduct')}
          </button>
        {/if}
        {#each products as product}
          <div class="product">
            <Link to={`/my/business/${id}/products/${product.slug}/edit`}>
              <div class="product_link" />
            </Link>
            <div>
              {#if (product.images || []).some(i => i.main)}
                <img
                  class="product_image"
                  alt="logo"
                  src={((product.images || []).find(i => i.main) || {}).url} />
              {/if}
            </div>
            <div class="product_description">
              <h4>{product.name}</h4>
              <p>{product.description}</p>
            </div>
          </div>
        {/each}
      {/if}
    </div>
  {/if}
</div>
