<script>
  import * as yup from "yup";
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import { translate } from "./translations/translate.js";
  import { user, instance, accessToken } from "./stores.js";

  if (!$accessToken) {
    navigate("/signin");
  }

  onMount(async () => {
    console.log("MOUNTED");
    const { data: userReview } = await $instance(`/reviews/user?id=${id}`);
    if (userReview != null) {
      navigate(`/product/${id}`);
    }
  });

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .required()
      .max(100),
    description: yup
      .string()
      .required()
      .max(500),
    rating: yup
      .number()
      .required()
      .positive()
      .max(5)
  });

  let validationErrors = [];
  const review = {
    title: "",
    description: "",
    rating: 3
  };
  async function handleSubmit(e) {
    validationErrors = [];
    e.preventDefault();
    try {
      await validationSchema.validate(review, {
        abortEarly: false
      });
    } catch (err) {
      validationErrors = err.inner;
      return;
    }

    const newReview = {
      title: review.title,
      description: review.description,
      rating: review.rating
    };
    await $instance.post(`/reviews?id=${id}`, newReview);
    navigate(`/product/${id}`);
  }
  export let id;
</script>

<style>
  .submit {
    max-width: 320px;
  }

  input:focus,
  textarea:focus {
    border: 1px solid #fdc9bf;
  }
</style>

<h5>{translate('addreview')}</h5>
<form on:submit={handleSubmit}>
  <div class="input_wrapper">
    <label>{translate('title')}</label>
    <input bind:value={review.title} />
    {#if validationErrors.some(e => e.path === 'title')}
      <div class="text-danger">
        {validationErrors.find(e => e.path === 'title').message}
      </div>
    {/if}
  </div>
  <div class="input_wrapper">
    <label>{translate('description')}</label>
    <textarea bind:value={review.description} />
    {#if validationErrors.some(e => e.path === 'description')}
      <div class="text-danger">
        {validationErrors.find(e => e.path === 'description').message}
      </div>
    {/if}
  </div>
  <div class="input_wrapper">
    <label>{translate('rating')}</label>
    <div class="text-left">
      {#each [1, 2, 3, 4, 5] as star}
        <i
          class="fas fa-star"
          on:click={() => {
            review.rating = star;
          }}
          class:text-secondary={star > review.rating}
          class:text-warning={star <= review.rating} />
      {/each}
    </div>
    <!-- <input type="number" bind:value={review.rating} /> -->
  </div>
  <button class="submit btn primary" type="submit">{translate('save')}</button>
</form>
