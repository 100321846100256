<script>
  import { setContext, onMount, onDestroy } from "svelte";
  import {
    search,
    category,
    user,
    idToken,
    instance,
    userProfilePicture,
    accessToken
  } from "./stores.js";
  import Product from "./Product.svelte";
  import Searchbar from "./components/Searchbar.svelte";
  import Home from "./Home.svelte";
  import SignIn from "./SignIn.svelte";
  import BusinessLandingPage from "./BusinessLandingPage.svelte";
  import TermsOfService from "./TermsOfService.svelte";
  import EditBusiness from "./EditBusiness.svelte";
  import NewBusiness from "./NewBusiness.svelte";
  import NewProduct from "./NewProduct.svelte";
  import EditProduct from "./EditProduct.svelte";
  import Public from "./Public.svelte";
  import AddReview from "./AddReview.svelte";
  import LoginRedirect from "./LoginRedirect.svelte";
  import { Router, Route, Link, link, navigate } from "svelte-routing";
  import { translate } from "./translations/translate.js";
  import axios from "axios";
  import { isNaN } from "lodash-es";
  let showDropdown = false;
  let dropdownRef, dropdownMenuRef;
  let userStateLoading = false;

  onMount(function() {
    document.body.addEventListener("click", e => {
      const isClickOnDropdownButton =
        e.target.closest("#dropdownBtn") === dropdownRef;
      const isClickOnDropdownNeu =
        e.target.closest("#menu_list") === dropdownMenuRef;
      if (showDropdown && !isClickOnDropdownButton && !isClickOnDropdownNeu) {
        showDropdown = false;
      }
    });
  });

  let searchToPerform = "";

  function searchOnEnter(e) {
    if (e.keyCode === 13) {
      performSearch();
    }
  }

  function goToBusiness() {
    navigate("/my/business");
  }

  function performSearch() {
    searchToPerform = $search;
    navigate(`/search?q=${encodeURIComponent(search)}`);
  }

  $instance = axios.create({
    baseURL: "__api__/api",
    timeout: 10000,
    headers: {}
  });
  const businessProductRegex = new RegExp("/businesses/.*/products/.*");

  $instance.interceptors.request.use(
    async function(config) {
      if (config.method === "options") {
        return config;
      }
      if (config.method === "get") {
        if (
          !config.url.startsWith("/reviews/user") &&
          !config.url.startsWith("/businesses/user") &&
          !businessProductRegex.test(config.url)
        ) {
          return config;
        }
      }
      const expiresAt = localStorage.getItem("expires_at");
      if (!$accessToken) {
        return config;
      }

      if ($accessToken && expiresAt > Date.now()) {
        config.headers.common["Authorization"] = `Bearer ${$accessToken}`;
        return config;
      }

      if ($accessToken && (!isNaN(expiresAt) || expiresAt <= Date.now())) {
        try {
          //refresh token
          const refreshToken = localStorage.getItem("refresh_token");
          if (!refreshToken) {
            throw new Error("Invalid auth state");
          }
          const { data } = await axios.get("__api__/api/auth/refresh", {
            headers: {
              Authorization: `Bearer ${refreshToken}`
            }
          });

          localStorage.setItem("access_token", data.accessToken);
          localStorage.setItem("refresh_token", data.refreshToken);
          localStorage.setItem(
            "expires_at",
            Date.now() + data.expiresIn * 1000
          );
          $accessToken = data.accessToken;
          config.headers.common["Authorization"] = `Bearer ${$accessToken}`;
          return config;
        } catch (err) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("profile_picture_url");
          localStorage.removeItem("expires_at");
          return config;
          throw new Error("Invalid auth state");
        }
      }

      config.headers.common["Authorization"] = `Bearer ${$accessToken}`;
      return config;
    },
    function(error) {
      navigate("/");
      return Promise.reject(error);
    }
  );
  async function logout() {
    showDropdown = false;
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("profile_picture_url");

    $accessToken = null;
    $userProfilePicture = null;
    window.FB.logout(function() {});
    navigate("/");
  }

  setContext("api", instance);

  const categories = [
    "food",
    "beauty",
    "clothes",
    "services",
    "accessories",
    "electronics",
    "home",
    "health"
  ];

  export let url = "";
</script>

<style>
  main {
    text-align: start;
    padding: 0;
    width: 100vw;
    margin: 0 auto;
    min-width: 320px;
    min-height: calc(100vh - 145px);
  }

  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }

    .header_left {
      margin-left: 15px;
    }
  }

  nav {
    height: 80px;
    width: 100%;
    background: var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header_left {
    display: flex;
    margin-left: 0;
  }

  .header_right {
    margin-right: 15px;
  }

  .content_wrapper {
    margin: var(--normal) auto;
    max-width: 1200px;
    width: calc(100% - var(--normal) * 2);
  }

  .profile_picture {
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
  }

  .logo {
    letter-spacing: var(--xs);
  }

  .menu {
    position: relative;
  }

  .dropdown-toggle:focus,
  .dropdown-toggle:active {
    outline: none;
  }

  #menu_list {
    position: absolute;
    top: 45px;
    right: 12px;
    background-color: white;
    padding: var(--xl);
    border: 1px solid var(--light-color);
    z-index: 1;
  }
</style>

<Router {url}>
  <main>
    <nav class="navbar navbar-dark">

      <div class="header_left">
        <a class="navbar-brand" href="/">
          <div class="logo">NGOHEM</div>
        </a>
      </div>
      <div>
        {#if $accessToken}
          <div class="menu btn-group">
            <button
              id="dropdownBtn"
              bind:this={dropdownRef}
              on:click={() => {
                showDropdown = !showDropdown;
              }}
              type="button"
              class="white-text btn dropdown-toggle"
              style="color:white;"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              {#if $userProfilePicture}
                <img
                  alt="user_picture"
                  class="profile_picture"
                  src={$userProfilePicture} />
              {/if}
              <span class="white-text">Xin chao!</span>
            </button>
            {#if showDropdown}
              <div id="menu_list" bind:this={dropdownMenuRef}>
                <a
                  class=""
                  on:click={() => {
                    showDropdown = false;
                  }}
                  use:link
                  href="/my/business">
                  {translate('mybusiness')}
                </a>
                <div class="dropdown-divider" />
                <a class="text-danger" on:click={logout} href="#">
                  {translate('signout')}
                </a>
              </div>
            {/if}
          </div>
        {:else}
          <button
            class="btn btn-outline-light"
            on:click={() => navigate('/signin')}>
            {translate('signin')}
          </button>
        {/if}
      </div>
    </nav>

    <div class="content_wrapper">
      <Route path="/signin" component={SignIn} />
      <Route path="/my/business/new" component={NewBusiness} />
      <Route path="/my/business" component={BusinessLandingPage} />
      <Route path="/my/business/:id" component={EditBusiness} let:params />
      <Route
        path="/my/business/:businessId/products/new"
        component={NewProduct}
        let:params />
      <Route
        path="/my/business/:businessId/products/:id/edit"
        let:params
        component={EditProduct} />
      <Route path="/product/:id/review" let:params component={AddReview} />
      <Route path="/terms" component={TermsOfService} />
    </div>
    <div class="content_wrapper">
      <Route path="*" component={Public} />
    </div>
  </main>
  <footer>
    <div>©2020 NgoHem</div>
    <div>
      <a
        target="_blank"
        rel="noopener"
        href="https://www.facebook.com/ngohemsite">
        {translate('contactus')}
      </a>
      <div>
        <Link to="/terms">{translate('termsofservicelink')}</Link>
      </div>
    </div>
  </footer>
</Router>
