<script>
  import { onMount } from "svelte";
  import { instance } from "./stores.js";
  import { isEmpty, intersection } from "lodash-es";
  import listedDistricts from "./districts.json";
  import { fly } from "svelte/transition";
  import ProductCard from "./ProductCard.svelte";
  import ProductCardSkeleton from "./ProductCardSkeleton.svelte";
  import Searchbar from "./components/Searchbar.svelte";
  import { translate } from "./translations/translate.js";
  import * as querystring from "query-string";

  const currentSearch = querystring.parse(window.location.search);

  let products = [];
  let cities = [];
  let districts = [];
  let categories = [];
  let ratingCounts = [];
  let loading = true;
  let filtersLoading = true;
  let error = null;
  let showFilters = false;
  let totalResults = 0;

  let page = 1;
  let category = currentSearch.cat;
  let city = currentSearch.city;
  let district = currentSearch.district;
  let rating = currentSearch.rating;
  let q = currentSearch.q;
  let filterCount = getFilterCount();

  let tempFilters = {
    rating: currentSearch.rating,
    category: currentSearch.cat,
    city: currentSearch.city,
    district: currentSearch.district
  };

  function getFilterCount() {
    let count = 0;
    if (!isEmpty(city)) {
      count += 1;
    }
    if (!isEmpty(district)) {
      count += 1;
    }
    if (!isEmpty(category)) {
      count += 1;
    }
    if (!isEmpty(rating)) {
      count += 1;
    }
    return count === 0 ? "" : `(${count})`;
  }

  function clearFilters() {
    tempFilters = {};
  }

  async function resetFilter(key) {
    switch (key) {
      case "category":
        category = null;
        updateURL("cat", category, category == null);
        break;
      case "city":
        city = null;
        updateURL("city", city, city == null);
        break;
      case "district":
        district = null;
        updateURL("district", district, district == null);
        break;
      case "rating":
        rating = null;
        updateURL("rating", rating, rating == null);
        break;
      case "q":
        q = null;
        updateURL("q", q, q == null || q == "");
        break;
    }
    await performSearch();
  }

  async function performSearch() {
    try {
      filtersLoading = true;
      if (page === 1) {
        loading = true;
      }
      let url = "/products/search";
      let countUrl = "/products/search/count";
      let filtersUrl = "/products/search/filters";
      let qParams = {};
      if (category) {
        qParams["cat"] = category;
      }
      if (city) {
        qParams["city"] = city;
      }
      if (district) {
        qParams["district"] = district;
      }
      if (rating > 0) {
        qParams["rating"] = rating;
      }
      if (q) {
        qParams["q"] = q;
      }
      if (page > 1) {
        qParams["p"] = page;
      }
      url = `${url}?${querystring.stringify(qParams)}`;
      countUrl = `${countUrl}?${querystring.stringify(qParams)}`;
      filtersUrl = `${filtersUrl}?${querystring.stringify(qParams)}`;
      const { data } = await $instance.get(url, { headers: [] });
      if (page > 1) {
        products = products.concat(data.products);
      } else {
        products = data.products;
      }
      loading = false;
      const { data: countData } = await $instance.get(countUrl, {
        headers: []
      });
      totalResults = countData.total;
      const { data: filtersData } = await $instance.get(filtersUrl, {
        headers: []
      });
      categories = filtersData.filters.categories;
      cities = filtersData.filters.cities;
      districts = filtersData.filters.districts.filter(
        x => !isEmpty(x.district)
      );
      ratingCounts = filtersData.filters.ratings;
    } catch (err) {
      console.error(err);
      products = [];
      error = err;
    } finally {
      loading = false;
      filtersLoading = false;
    }
  }

  function anyDistrictsMatchChosenCity(city, districts) {
    const districtNames = districts.map(x => x.district);
    return !isEmpty(intersection(getDistricts(city), districtNames));
  }

  function updateURL(key, val, _delete = false) {
    const currentSearch = querystring.parse(window.location.search);
    if (_delete) {
      delete currentSearch[key];
    } else {
      currentSearch[key] = val;
    }
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?${querystring.stringify(currentSearch)}`;

    window.history.pushState({}, window.title, newurl);
  }

  function closeFilterMenu() {
    showFilters = false;
  }
  const getDistricts = city => listedDistricts[city] || [];

  async function getNextResults() {
    page = page + 1;
    await performSearch();
  }

  $: if (q || (isEmpty(q) && currentSearch.q)) {
    updateURL("q", q, isEmpty(q));
    performSearch();
  }

  onMount(async () => {
    await performSearch();
  });
</script>

<style>
  @media (min-width: 425px) {
    .results {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (min-width: 576px) {
    .results {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    .results {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 1200px) {
    .results {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .results {
    display: grid;
    grid-gap: var(--normal);
    margin: var(--normal) 0;
    list-style: none;
  }
  .refinements {
    display: flex;
    position: fixed;
    width: 100vw;
    flex-direction: column;
    z-index: 20;
    left: -100vw;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    top: 0px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ease-in-out;
  }

  .refinement_section {
    margin: var(--xl) 0;
  }

  .refinement_content {
    max-width: 568px;
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
  }
  .pointer {
    cursor: pointer;
  }
  .show {
    left: 0px;
  }
</style>

<Searchbar
  searchCb={searchTerm => {
    q = searchTerm;
  }} />
<div class="text-left">
  <button
    class="btn primary"
    on:click={() => {
      showFilters = true;
    }}>
    {translate('filters')} {filterCount}
  </button>
</div>
{#if !filtersLoading}
  <div
    class:show={showFilters}
    class:d-none={filtersLoading && !showFilters}
    class="refinements">
    <div class="refinement_content">
      <div class="text-left">
        <i class="fas fa-times pointer" on:click={closeFilterMenu} />
      </div>
      <div class="refinement_section">
        <h5>{translate('category')}</h5>
        {#if products.length > 0}
          {#each categories as _category}
            <div
              class="pointer"
              class:font-weight-bold={_category.category === tempFilters.category}
              on:click={() => {
                tempFilters.category = _category.category;
              }}>
              {translate(_category.category)} ({_category.count})
            </div>
          {/each}
        {/if}
      </div>
      <div class="refinement_section">
        <h5>{translate('city')}</h5>
        {#if products.length > 0}
          {#each cities as _city}
            <div
              class:font-weight-bold={_city.city === tempFilters.city}
              class="pointer"
              on:click={() => {
                tempFilters.city = _city.city;
              }}>
              {_city.city} ({_city.count})
            </div>
          {/each}
        {/if}
        <div id="city" />
      </div>
      {#if tempFilters.city && !isEmpty(getDistricts(tempFilters.city)) && anyDistrictsMatchChosenCity(tempFilters.city, districts)}
        <div class="refinement_section">
          <h5>{translate('district')}</h5>
          {#if products.length > 0}
            {#each districts as _district}
              <div
                class:font-weight-bold={_district.district === tempFilters.district}
                class="pointer"
                on:click={() => {
                  tempFilters.district = _district.district;
                }}>
                {_district.district} ({_district.count})
              </div>
            {/each}
          {/if}
          <div id="city" />
        </div>
      {/if}
      <div class="refinement_section">
        <h5>{translate('rating')}</h5>
        <div id="ratings">
          {#if products.length > 0 && !loading}
            {#each [1, 2, 3, 4, 5] as _rating}
              <div
                class:font-weight-bold={_rating === tempFilters.rating}
                class="pointer"
                on:click={() => {
                  tempFilters.rating = _rating;
                }}>
                {#each [1, 2, 3, 4, 5] as r}
                  <i
                    class="fas fa-star"
                    class:text-warning={r <= _rating}
                    class:text-secondary={r > _rating} />
                {/each}
                ({ratingCounts.find(x => x.rating === _rating).count})
              </div>
            {/each}
          {/if}
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <button on:click={clearFilters} class="btn secondary_action">
          {translate('clear')}
        </button>
        <button
          on:click={() => {
            rating = tempFilters.rating;
            category = tempFilters.category;
            city = tempFilters.city;
            district = tempFilters.district;
            page = 1;
            updateURL('rating', rating, rating <= 0);
            updateURL('city', city, city === null);
            updateURL('district', district, district === null);
            updateURL('category', category, category === null);
            closeFilterMenu();
            performSearch();
          }}
          class="btn primary">
          {translate('search')}
        </button>
      </div>
    </div>
  </div>
{/if}
<div class="results">
  {#if loading}
    {#each [1, 2, 3, 4, 5] as sk}
      <ProductCardSkeleton />
    {/each}
  {:else if products.length === 0}
    <div>{translate('noproducts')}</div>
  {:else}
    {#each products as product}
      <ProductCard {product} />
    {/each}
  {/if}
</div>
{#if totalResults > products.length && products.length !== 0}
  <div>
    <button class="btn btn-primary" on:click={getNextResults}>
      {translate('showmore')}
    </button>
  </div>
{/if}
