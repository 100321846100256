<script>
  import { accessToken, search } from "../stores.js";
  import { navigate } from "svelte-routing";
  import { translate } from "../translations/translate.js";
  import * as querystring from "query-string";
  const currentSearch = querystring.parse(window.location.search);
  let searchToPerform = currentSearch.q;

  function searchOnEnter(e) {
    if (e.keyCode === 13) {
      performSearch();
    }
  }
  function performSearch() {
    searchCb(searchToPerform);
  }
  export let searchCb;
</script>

<style>
  .search_input {
    border-radius: 5px;
    width: auto;
    flex-grow: initial;
    flex: 1;
  }

  .search_input:focus {
    border: 1px solid #fdc9bf;
  }

  .search_input_wrapper {
    width: 100%;
    margin-bottom: var(--normal);
    display: inline-flex;
  }
</style>

<div class="search_input_wrapper">
  <input
    type="search"
    class="search_input"
    placeholder="Tim kiem..."
    bind:value={searchToPerform}
    on:keydown={searchOnEnter} />
</div>
