<script>
  import { translate } from "../translations/translate.js";

  const categories = [
    {
      option: "food",
      icon: "fa-utensils"
    },
    {
      option: "beauty",
      icon: "fa-gem"
    },
    {
      option: "clothes",
      icon: "fa-tshirt"
    },
    {
      option: "services",
      icon: "fa-hammer"
    },
    {
      option: "accessories",
      icon: "fa-shopping-bag"
    },
    {
      option: "electronics",
      icon: "fa-tv"
    },
    {
      option: "home",
      icon: "fa-blender"
    },
    {
      option: "health",
      icon: "fa-heartbeat"
    }
  ];
  export let product;
  export let cb;
</script>

<style>
  .category_choice {
    display: flex;
    flex-direction: column;
    padding: 15px;
    align-items: center;
    border: 1px solid lightgray;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 15px;
    text-align: center;
  }
  @media (min-width: 425px) {
    .category_choices {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (min-width: 576px) {
    .category_choices {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    .category_choices {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 1200px) {
    .category_choices {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .category_choices {
    display: grid;
    grid-gap: 15px;
  }
</style>

<div class="category_choices">
  {#each categories as category}
    <div
      class:primary={product.category === category.option}
      class="category_choice"
      on:click={() => cb(category.option)}>
      <i class={`fas ${category.icon} fa-2x`} />
      <b>{translate(category.option)}</b>
    </div>
  {/each}
</div>
