<script>
  export let id;
  export let businessId;
  import formatRelative from "date-fns/formatRelative";
  import ImageUploader from "./components/ImageUploader.svelte";
  import { instance, user, accessToken } from "./stores.js";
  import { NotificationDisplay, notifier } from "@beyonk/svelte-notifications";
  import { slide } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import { onMount, onDestroy } from "svelte";
  import Dropdown from "./components/Dropdown.svelte";
  import { translate } from "./translations/translate.js";
  import CategoryOptions from "./components/CategoryOptions.svelte";
  import { fly } from "svelte/transition";
  import { debounce, isEqual, omit, pick, isEmpty } from "lodash-es";
  import ProductCard from "./ProductCard.svelte";
  import uuidv4 from "uuid/v4";
  import { uploadDataURLs } from "./image_util.js";
  import { navigate, Link } from "svelte-routing";
  import { vi } from "date-fns/locale";
  import * as yup from "yup";

  const MAX_IMAGES_ALLOWED = 5;
  let loadingProduct = true;
  let n; // for notificationdisplay

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required()
      .max(100),
    description: yup.string().max(1000),
    price: yup
      .number()
      .positive()
      .max(10000000000),
    category: yup.string().required(),
    tags: yup
      .array()
      .of(yup.string().max(100))
      .max(10),
    images: yup
      .array()
      .min(1)
      .max(5)
  });

  if (!$accessToken) {
    navigate("/signin");
  }

  onMount(async () => {
    window.scrollTo(0, 0);
    try {
      if (!$user) {
        navigate("/");
        return;
      }
      const { data } = await $instance.get(
        `/businesses/${businessId}/products/${id}`
      );
      product = data.product;
      originalProduct = { ...data.product };
      publishedProduct = { ...data.published_version };

      hasChanges = !isEqual(
        omit(product, ["updated_at", "created_at", "has_published_version"]),
        omit(originalProduct, [
          "updated_at",
          "created_at",
          "has_published_version"
        ])
      );
      hasPendingPublishChanges =
        !isEmpty(publishedProduct) &&
        !isEqual(pick(product, diffFields), pick(publishedProduct, diffFields));

      updatedAt = formatRelative(
        new Date(data.product.updated_at),
        new Date(),
        {
          locale: vi
        }
      );
    } catch (err) {
      console.error(err);
      productLoadingError = err;
    } finally {
      loadingProduct = false;
    }
  });

  const diffFields = [
    "id",
    "name",
    "description",
    "category",
    "price",
    "images",
    "tags"
  ];
  let hasPendingPublishChanges = false;
  let imageUploading = false;
  let productLoadingError = "";
  let updatedAt = "";
  let productPublishing = false;
  let productUnpublishing = false;
  let product = {};
  let publishedProduct = {};
  let originalProduct = {};
  let newTag = "";
  let validationErrors = [];
  let saved = false;
  let saving = false;
  let deleting = false;
  let hasChanges = !isEqual(product, originalProduct);
  let showTagsHelper = false;
  let showImagesHelper = false;

  $: if (true) {
    hasChanges = !isEqual(
      omit(product, ["updated_at"]),
      omit(originalProduct, ["updated_at"])
    );
  }
  function setCategory(category) {
    product.category = category;
  }

  function addTag(e) {
    if (e.which === 13) {
      product.tags = product.tags.concat([newTag]);
      newTag = "";
    }
  }
  function addTagButton() {
    product.tags = product.tags.concat([newTag]);
    newTag = "";
  }

  function deleteTag(tagToRemove) {
    product.tags = product.tags.filter(x => x !== tagToRemove);
  }

  async function setAsLogo(image) {
    let newProductImages = [];
    const imageIdx = product.images.indexOf(image);
    for (let i = 0; i < product.images.length; i++) {
      if (i === imageIdx) {
        product.images[i].main = true;
      } else {
        product.images[i].main = false;
      }
      newProductImages.push(product.images[i]);
    }
    try {
      const { data } = await $instance.patch(
        `/products?id=${id}&field=images`,
        {
          images: newProductImages
        }
      );
      saved = true;
      setTimeout(() => {
        saved = false;
      }, 1000);
    } catch (err) {
      console.error(err);
    }
  }

  async function deleteImage(image) {
    await storage.refFromURL(image.url).delete();
    let newProductImages = product.images;

    newProductImages.splice(product.images.indexOf(image), 1);
    if (image.main && newProductImages.length > 0) {
      newProductImages[0].main = true;
    }
    product.images = newProductImages;
  }

  let updatedDate = "";

  async function deleteProduct() {
    deleting = true;
    try {
      await $instance.delete(`/businesses/${businessId}/products/${id}`);
      navigate(`/my/business/${businessId}`);
    } catch (err) {
      console.error(err);
    } finally {
      deleting = false;
    }
  }

  async function save() {
    saving = true;
    validationErrors = [];
    try {
      await validationSchema.validate(product, {
        abortEarly: false
      });
    } catch (err) {
      validationErrors = err.inner;
      console.error(validationErrors[0]);
      saving = false;
      return;
    }

    try {
      let urls = product.images.filter(x => x.url.startsWith("data:"));
      if (urls.length > 0) {
        const newImages = await uploadDataURLs(
          $instance,
          urls,
          "product_images",
          urls.length
        );
        const alreadyUploadedImages = product.images.filter(
          x => !x.url.startsWith("data:")
        );
        product.images = alreadyUploadedImages.concat(newImages);
      }
    } catch (err) {
      saving = false;
      console.error(err);
    }

    try {
      const { data } = await $instance.put(
        `/businesses/${businessId}/products/${id}`,
        product
      );
      originalProduct = { ...product };
      notifier.success(translate("saved"), 2000);
      updatedAt = formatRelative(new Date(data.updated_at), new Date(), {
        locale: vi
      });
      originalProduct = { ...product };
      hasPendingPublishChanges =
        !isEmpty(publishedProduct) &&
        !isEqual(pick(product, diffFields), pick(publishedProduct, diffFields));
    } catch (err) {
      notifier.danger(translate("servererror"), 2000);
    } finally {
      saving = false;
    }
  }

  async function publish(e) {
    productPublishing = true;
    validationErrors = [];
    try {
      await validationSchema.validate(product, {
        abortEarly: false
      });
    } catch (err) {
      validationErrors = err.inner;
      console.error(validationErrors[0]);
      productPublishing = false;
      return;
    }

    try {
      const { data } = await $instance.post(
        `/businesses/${businessId}/products/${id}/publish`
      );
      product = data;
      saved = true;
      notifier.success(translate("published"), 2000);
      publishedProduct = { ...product };
      originalProduct = { ...product };
      hasPendingPublishChanges = false;
    } catch (err) {
      notifier.danger(translate("servererror"), 2000);
    } finally {
      productPublishing = false;
    }
  }

  async function unpublish(e) {
    productUnpublishing = true;
    e.preventDefault();
    try {
      const { data } = await $instance.post(
        `/businesses/${businessId}/products/${id}/unpublish`
      );
      notifier.success(translate("unpublished"), 2000);
      publishedProduct = null;
      originalProduct = { ...product };
      hasChanges = false;
      hasPendingPublishChanges = false;
    } catch (err) {
      notifier.danger(translate("servererror"), 2000);
    } finally {
      productUnpublishing = false;
    }
  }
</script>

<style>
  .tags {
    display: flex;
  }

  .tag {
    cursor: pointer;
    margin-right: 5px;
  }

  .price {
    width: 150px;
  }

  .delete {
    cursor: pointer;
    text-decoration: underline;
    display: block;
  }

  .clickable {
    cursor: pointer;
  }

  .btn-spacing {
    margin: 10px 0;
  }
</style>

<NotificationDisplay bind:this={n} />

<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <Link to="/">{translate('homepage')}</Link>
    </li>
    <li class="breadcrumb-item">
      <Link to="/my/business">{translate('mybusiness')}</Link>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      {translate('edit')} {(product || {}).name || '...'}
    </li>
  </ol>
</nav>
{#if loadingProduct}
  <div>{translate('loading')}...</div>
{:else if productLoadingError}
  <div>An error occured!</div>
{:else}
  <small>{translate('lastupdated')} {updatedAt}</small>
  <div novalidate style="text-align:start" on:submit={save}>
    <div class="form-group">
      <label for="name">{translate('name')}</label>
      <input name="name" bind:value={product.name} class="form-control" />
      {#if validationErrors.some(e => e.path === 'name')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'name').message}
        </div>
      {/if}
    </div>
    <div class="form-group">
      <label for="description">{translate('description')}</label>
      <textarea
        name="description"
        bind:value={product.description}
        class="form-control" />
      {#if validationErrors.some(e => e.path === 'description')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'description').message}
        </div>
      {/if}
    </div>
    <div class="form-group">
      <label for="description">{translate('price')}</label>
      <div class="input-group mb-2 price">
        <input
          name="price"
          type="number"
          bind:value={product.price}
          class="form-control" />
        <div class="input-group-prepend">
          <div class="input-group-text">₫</div>
        </div>
      </div>
      {#if validationErrors.some(e => e.path === 'price')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'price').message}
        </div>
      {/if}
    </div>
    <div class="form-group">
      <label for="description">{translate('category')}</label>
      <CategoryOptions {product} cb={setCategory} />
      {#if validationErrors.some(e => e.path === 'category')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'category').message}
        </div>
      {/if}
    </div>
    <label>{translate('tags')}</label>
    <i
      class="fas fa-question-circle clickable"
      on:click={() => (showTagsHelper = !showTagsHelper)} />
    {#if showTagsHelper}
      <small>{translate('tagshelper')}</small>
    {/if}
    <div class="input-group mb-3">
      <input
        on:keydown={addTag}
        bind:value={newTag}
        type="text"
        class="form-control"
        placeholder="{translate('newtag')}..."
        aria-label="{translate('newtag')}..."
        aria-describedby="button-addon2" />
      <div class="input-group-append">
        <button
          class="btn primary"
          type="button"
          id="button-addon2"
          on:click={addTagButton}>
          {translate('newtag')}
        </button>
      </div>
    </div>
    {#if product.tags}
      <div class="tags form-group">
        {#each product.tags as tag}
          <span class="tag badge primary" on:click={() => deleteTag(tag)}>
            {tag}
          </span>
        {/each}
        {#if validationErrors.some(e => e.path === 'tags')}
          <div class="text-danger">
            {validationErrors.find(e => e.path === 'tags').message}
          </div>
        {/if}
      </div>
    {:else}
      <div>{translate('notags')}</div>
    {/if}
    <div class="form-group">
      <label for="images">{translate('images')}</label>
      <i
        class="fas fa-question-circle clickable"
        on:click={() => (showImagesHelper = !showImagesHelper)} />
      {#if showImagesHelper}
        <small>{translate('imageshelper')}</small>
      {/if}
      <ImageUploader
        height={900}
        width={1200}
        previewHeight={100}
        fileLimit={5}
        showMainOption={true}
        loading={imageUploading}
        bind:images={product.images} />
    </div>
    {#if validationErrors.some(e => e.path === 'images')}
      <div class="text-danger">
        {validationErrors.find(e => e.path === 'images').message}
      </div>
    {/if}
    <div class="d-block">
      {#if !isEqual(omit(product, [
          'updated_at'
        ]), omit(originalProduct, ['updated_at']))}
        <button class="d-block btn primary btn-spacing" on:click={save}>
          {#if saving}
            <i class="fas fa-spinner fa-spin" />
          {:else}{translate('save')}{/if}
        </button>
        <small>{translate('saverequired')}</small>
      {/if}
      {#if !hasChanges && !isEmpty(publishedProduct)}
        <button
          class="d-block btn secondary_action btn-spacing"
          disabled={productUnpublishing}
          on:click={unpublish}>
          {#if productUnpublishing}
            <i class="fas fa-spinner fa-spin" />
          {:else}{translate('unpublish')}{/if}
        </button>
      {/if}
      {#if hasPendingPublishChanges}
        <button
          class="d-block btn secondary_action btn-spacing"
          disabled={productPublishing}
          on:click={publish}>
          {#if productPublishing}
            <i class="fas fa-spinner fa-spin" />
          {:else}{translate('publishchanges')}{/if}
        </button>
      {/if}
      {#if isEmpty(publishedProduct) && !hasChanges}
        <button
          class="d-block secondary_action btn btn-spacing"
          disabled={productPublishing}
          on:click={publish}>
          {#if productPublishing}
            <i class="fas fa-spinner fa-spin" />
          {:else}{translate('publish')}{/if}
        </button>
      {/if}
      {#if isEmpty(publishedProduct)}
        <div
          class="delete text-secondary"
          disabled={deleting}
          on:click={deleteProduct}>
          {#if deleting}
            <i class="fas fa-spinner fa-spin" />
          {:else}
            <i class="fas fa-trash" />
          {/if}
          {translate('delete')}
        </div>
      {/if}
    </div>
  </div>
{/if}
