<script>
  import { translate } from "../translations/translate.js";

  export let hours = [];
  let days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
    "weekdays",
    "weekends"
  ];
  const addHour = () => {
    const newHourSlot = { day: 0, startHour: 0, endHour: 0 };
    hours = hours.concat(newHourSlot);
  };

  const deleteHour = hour => {
    hours = hours.filter(x => x !== hour);
  };
</script>

<style>
  .hour-slot {
    border: none;
    font-size: 1em;
    height: 35px;
  }
  .add {
    cursor: pointer;
  }

  .delete {
    margin-left: var(--normal);
    color: var(--light-primary-gradient-color);
    cursor: pointer;
  }
</style>

<div>
  {#each hours as hour}
    <div>
      <select
        class="hour-slot"
        value={parseInt(hour.day)}
        on:change={e => {
          console.log(e.target.value);
          hour.day = e.target.value;
        }}>
        {#each days as day}
          <option value={days.indexOf(day)}>{translate(day)}</option>
        {/each}
      </select>
      <input type="time" bind:value={hour.startHour} />
      <input type="time" bind:value={hour.endHour} />
      <i class="fas fa-times-circle delete" on:click={() => deleteHour(hour)} />
    </div>
  {/each}
  <span class="add" on:click={addHour}>+ Add hours</span>
</div>
