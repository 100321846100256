<script>
  import Cropper from "svelte-easy-crop";
  import { translate } from "../translations/translate.js";

  let tempImg = "";
  let finalCrop = {};

  async function handleImageUpload(e) {
    for (
      let i = 0;
      i <= fileLimit - images.length - e.target.files.length;
      i++
    ) {
      const file = e.target.files[i];
      tempImg = URL.createObjectURL(file);
      showCrop = true;
    }
  }

  async function setCrop(e) {
    console.log(e.detail.pixels);
    finalCrop = e.detail.pixels;
  }

  async function saveCrop(e) {
    e.preventDefault();
    const img = new Image();
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    img.src = tempImg;
    img.onload = async () => {
      ctx.drawImage(
        img,
        finalCrop.x,
        finalCrop.y,
        finalCrop.width,
        finalCrop.height,
        0,
        0,
        width,
        height
      );
      try {
        if (images.length == 0) {
          images = images.concat([
            { url: canvas.toDataURL("image/jpeg", 0.7), main: true }
          ]);
        } else {
          images = images.concat([
            { url: canvas.toDataURL("image/jpeg", 0.7) }
          ]);
        }
      } finally {
        showCrop = false;
      }
    };
  }

  function showCropOption() {
    showCrop = true;
  }

  function chooseImage(idx) {
    if (chosenImage === idx) {
      chosenImage = null;
    } else {
      chosenImage = idx;
    }
  }

  let showCrop = false;
  let crop = { x: 0, y: 0 };
  let chosenImage = null;

  let zoom = 1;
  export let loading = false;
  export let width;
  export let height;
  export let previewHeight = height;
  export let aspectRatio = 4 / 3;
  export let fileLimit = 1;
  export let images = [];
  export let showMainOption = false;

  function deleteImage(img) {
    images = images.filter(x => x !== img);
    if (img.main && images.length > 0) {
      images[0].main = true;
    }
    chosenImage = null;
  }

  function chooseAsMain(img) {
    let newImages = [];
    for (let i of images) {
      if (i === img) {
        i.main = true;
      } else {
        i.main = false;
      }
      newImages.push(i);
    }
    images = newImages;
    chosenImage = null;
  }
</script>

<style>
  .file_input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    cursor: pointer;
  }

  .file_input_group {
    width: 100px;
    height: 100px;
    position: relative;
  }

  .file_input_label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: blue;
    border-radius: 5px;
  }

  .image_gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, 130px);
    grid-gap: var(--normal);
  }

  .modality {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .save-crop {
    position: absolute;
    bottom: 10px;
  }
  .clickable {
    cursor: pointer;
  }

  .main-image {
    position: absolute;
    top: 10px;
    right: 10px;
  }
</style>

<div>
  <div class="image_gallery">
    {#each images as image, idx}
      <div style="position:relative">
        {#if image.main && showMainOption}
          <i class="fas fa-star text-warning main-image" />
        {/if}
        <img
          style={`height:${previewHeight}px;`}
          class="clickable"
          alt="image"
          src={image.url}
          on:click={() => chooseImage(idx)} />
        {#if chosenImage === idx}
          <div>
            <i
              class="clickable fas fa-trash text-danger"
              on:click={() => deleteImage(image)} />
            {#if showMainOption}
              <i
                on:click={() => chooseAsMain(image)}
                class:text-warning={image.main}
                class="clickable fas fa-star" />
            {/if}
          </div>
        {/if}
      </div>
    {/each}
    {#if fileLimit - images.length > 0}
      <div class="file_input_group">
        <label class="file_input_label primary" for="validatedCustomFile">
          {#if loading}
            <i class="fas fa-spinner fa-spin fa-2x" />
          {:else}
            <i class="fas fa-plus fa-2x" />
          {/if}
        </label>
        <input
          type="file"
          accept="image/*"
          on:change={handleImageUpload}
          class="file_input" />
      </div>
    {/if}
  </div>
</div>
<div class:d-none={!showCrop} class="modality">
  <Cropper
    on:cropcomplete={setCrop}
    image={tempImg}
    aspect={aspectRatio}
    restrictPosition={false}
    minZoom={0.5}
    bind:crop
    bind:zoom />
  <button class="btn primary save-crop" on:click={saveCrop}>
    {translate('save')}
  </button>
</div>
