<script>
  import { onMount } from "svelte";
  import ProductCard from "./ProductCard.svelte";
  import { instance } from "./stores.js";
  import { translate } from "./translations/translate.js";
  export let id;

  let loading = true;
  let error = "";
  let products = [];
  let business = {};

  onMount(async function() {
    window.scrollTo(0, 0);
    try {
      const { data: businessData } = await $instance(`/businesses/${id}`);
      business = businessData;
      const { data } = await $instance(`/products/list?id=${id}&pub=true`);
      products = data;
      products = products.map(p => ({
        ...p,
        business_name: businessData.name,
        business_city: businessData.city
      }));
    } catch (err) {
      error = translate("servererror");
      console.error(err);
    } finally {
      loading = false;
    }
  });
</script>

<style>
  @media (min-width: 425px) {
    .results {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (min-width: 576px) {
    .results {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 992px) {
    .results {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 1200px) {
    .results {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .results {
    display: grid;
    grid-gap: var(--normal);
    margin: var(--normal) 0;
    list-style: none;
  }

  .business_meta {
    display: flex;
    align-items: center;
    margin-bottom: var(--xl);
  }

  .business_img {
    width: 30px;
  }

  .business_name {
    margin-bottom: 0;
    margin-left: var(--normal);
  }
</style>

{#if loading}
  <div>Loading...</div>
{:else if error != ''}
  <div>{error}</div>
{:else if products.length === 0}
  <div>No Results...</div>
{:else}
  <div class="business_meta">
    <img class="business_img" src={business.logo} alt="logo" />
    <h5 class="business_name">{business.name}</h5>
  </div>
  <div class="results">
    {#each products as product}
      <ProductCard {product} />
    {/each}
  </div>
{/if}
