<script>
  import { Router, Route } from "svelte-routing";
  import LoginRedirect from "./LoginRedirect.svelte";
  import Searchbar from "./components/Searchbar.svelte";
  import Home from "./Home.svelte";
  import Product from "./Product.svelte";
  import BusinessPage from "./BusinessPage.svelte";
</script>

<Router>
  <Route path="/business/:id" let:params>
    <BusinessPage id={params.id} />
  </Route>
  <Route path="/product/:id" let:params>
    <Product id={params.id} />
  </Route>
  <Route path="/login" component={LoginRedirect} />
  <Route path="/" component={Home} />
</Router>
