<script>
  import { user, instance, userProfilePicture, accessToken } from "./stores.js";
  import { translate } from "./translations/translate.js";
  import { navigate, Link } from "svelte-routing";

  let error = "";

  if (localStorage.getItem("access_token")) {
    navigate("/");
  }

  async function loginWithFacebook() {
    window.FB.login(
      function(response) {
        console.log(response);
        if (response.authResponse) {
          $instance(
            `/auth/login/facebook?token=${response.authResponse.accessToken}`
          ).then(({ data }) => {
            $userProfilePicture = data.profilePictureUrl;
            localStorage.setItem("profile_picture_url", data.profilePictureUrl);
            localStorage.setItem("access_token", data.accessToken);
            localStorage.setItem("refresh_token", data.refreshToken);
            localStorage.setItem(
              "expires_at",
              Date.now() + data.expiresIn * 1000
            );
            $accessToken = data.accessToken;
            navigate("/");
          });
        } else {
          error =
            "Unable to connect with Facebook at this time.  Please try again later.";
        }
      },
      { scopes: "email,public_profile" }
    );
  }
</script>

<style>
  .container {
    text-align: center;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 80px;
    max-width: 100vw;
    width: 100vw;
  }
  .login {
    background: white;
    border-radius: 5px;
    padding: var(--xl);
  }

  .facebook {
    color: white;
    background: linear-gradient(to right, #396afc, #2948ff);
    margin: var(--xl);
  }

  .facebook:hover {
    color: white;
  }

  #header {
    color: var(--light-primary-gradient-color);
    letter-spacing: 0.1em;
    text-align: center;
    margin-bottom: var(--xl);
    font-size: var(--xl);
  }
</style>

<div
  class="container"
  style="background-image:url('https://images.ngohem.com/LoginSplash.jpg')">
  <div class="login">
    <h5 id="header">NGOHEM</h5>
    <!-- {#if ui.isPendingRedirect()}{translate('signinwait')}{/if} -->
    <div id="firebaseui-auth-container" />
    <div>
      <button class="btn facebook" on:click={loginWithFacebook}>
        {translate('signinfb')}
      </button>
    </div>
    <small>{translate('facebookperm')}</small>
    {#if error}
      <div class="text-danger">{error}</div>
    {/if}
    <div>
      <Link to="/terms">
        <small>{translate('bysigninguptos')}</small>
      </Link>
    </div>
  </div>
</div>
