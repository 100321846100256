<script>
  import { onMount } from "svelte";
  import { instance } from "./stores.js";
  import { navigate, Link } from "svelte-routing";
  import { sortBy } from "lodash-es";

  let canCreateMultipleBusinesses = false;
  let businesses = [];

  onMount(async () => {
    const { data } = await $instance.get("/businesses/user");
    canCreateMultipleBusinesses = data.allowMultiple;
    businesses = sortBy(data.businesses, b => b.name);

    if (businesses.length === 0) {
      navigate("/my/business/new");
    }

    if (!canCreateMultipleBusinesses && businesses.length > 0) {
      navigate(`/my/business/${businesses[0].slug}`);
    }
  });
</script>

<style>
  .business {
    margin: 1em 0;
    border-bottom: 1px solid lightgray;
    padding: 1em;
  }

  span {
    margin-left: 1em;
  }

  img {
    height: 50px;
    width: 50px;
  }
</style>

<div>
  {#each businesses as business}
    <div class="business">
      <Link to={`/my/business/${business.slug}`}>
        <img src={business.logo} alt="logo" />
        <span>{business.name}</span>
      </Link>
    </div>
  {/each}
  <Link to={`/my/business/new`}>
    <button class="btn primary">Create Business</button>
  </Link>
</div>
