import axios from 'axios';

// takes in an array of data URLs and returns array of uploaded URLa
export async function uploadDataURLs(instance, urls, path, count = 1) {
    const uploadPromises = [];
    let signedURLs = [];
    try {
        const {
            data
        } = await instance.post(`/signedurl?path=${path}&count=${count}`);
        signedURLs = data.signedURLs
    } catch (err) {
        throw new Error('Unable to get signed URLs for upload')
    }

    for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        const signedURL = signedURLs[i];
        const promise = new Promise(async (resolve, reject) => {
            try {
                let blob = await fetch(url.url).then(r => r.blob());
                await axios.put(signedURL, blob, {
                    headers: {
                        "content-type": "image/jpeg"
                    }
                });
                var path = signedURL
                    .slice("https://storage.googleapis.com/images.ngohem.com/".length)
                    .split("?")[0];
                resolve({
                    url: `https://images.ngohem.com/${path}`,
                    main: url.main
                })
            } catch (err) {
                reject(err);
            }
        })
        uploadPromises.push(promise)
    }
    const finalUrls = await Promise.all(uploadPromises)
    console.log(finalUrls)
    return finalUrls
}
