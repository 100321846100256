<script>
  export let id;

  import { getContext, onMount } from "svelte";
  import { user, instance, accessToken } from "./stores.js";
  import ReviewCard from "./components/ReviewCard.svelte";
  import ProductCard from "./ProductCard.svelte";
  import { isEmpty } from "lodash-es";
  import ImageSlider from "./components/ImageSlider.svelte";

  import { Router, Route, Link, link } from "svelte-routing";
  import { translate } from "./translations/translate.js";

  let days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
    "weekdays",
    "weekends"
  ];
  let chosenImage = "";
  let product = {};
  let business = {};
  let reviews = [];
  let avgRating = 0;
  let numRatings = 0;
  let loading = true;
  let error = null;
  let doesUserHaveReview = false;
  let userOwnsProduct = false;
  let showClaimBusinessModal = false;
  let claimError = null;
  let claimLoading = false;
  let claimSuccess = false;

  const claimBusiness = async () => {
    claimLoading = true;
    claimSuccess = false;
    try {
      const response = await $instance.put(
        `/businesses/${business.slug}/claim`
      );
      claimSuccess = true;
    } catch (error) {
      switch (error.response.status) {
        case 406:
          claimError = translate("businessalreadyclaimed");
          return;
        case 400:
          claimError = translate("toomanybusinesses");
          return;
        default:
          claimError = translate("servererror");
      }
    } finally {
      claimLoading = false;
    }
  };

  const closeModal = () => {
    showClaimBusinessModal = false;
    claimError = null;
  };

  const makeRootURL = url => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    } else {
      return `//${url}`;
    }
  };

  onMount(async () => {
    window.scrollTo(0, 0);
    try {
      const { data: productData } = await $instance(`/products/${id}`);
      const { data: reviewData } = await $instance(`/reviews/list?id=${id}`);
      product = productData.product;
      business = productData.business;
      numRatings = productData.num_ratings;
      avgRating = productData.avg_rating;
      chosenImage = ((product.images || []).find(i => i.main) || {}).url;
      reviews = reviewData;
      if ($accessToken) {
        const { data: userReview } = await $instance(`/reviews/user?id=${id}`);
        doesUserHaveReview = userReview != null;
      } else {
        doesUserHaveReview = false;
      }
    } catch (err) {
      console.error(err);
      error = err;
    } finally {
      loading = false;
    }
  });
</script>

<style>
  .product_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wrapper {
    width: 100%;
    max-width: 768px;
    text-align: start;
  }

  .info_section {
    margin: var(--xl) 0;
  }
  .product_details {
    font-weight: 300;
    font-size: var(--medium);
  }
  .light_weight {
    font-weight: 300;
  }

  .reviews {
    margin: var(--normal) 0;
  }

  .contact_section {
    margin: var(--normal) 0;
  }

  .hours {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 150px 1fr;
  }
  .business_description {
    white-space: pre-wrap;
  }
  #modality {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    display: flex;
    align-items: center;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }

  #modal_body {
    background-color: white;
    padding: 1em;
    max-width: 320px;
    border-radius: 0.5em;
    text-align: center;
  }

  #claim_link {
    cursor: pointer;
    color: #007bff;
  }

  #modal_buttons {
    display: flex;
    justify-content: center;
    margin: var(--xl) 0;
  }

  .ok_button {
    width: 100%;
    margin-top: var(--xl);
  }

  .claim_success {
    text-align: center;
    display: block;
    margin-bottom: var(--small);
    color: #28a745;
  }
</style>

{#if showClaimBusinessModal}
  <div id="modality">
    <div id="modal_body">
      {#if !claimSuccess}
        {translate('areyousureclaim')}
        <div id="modal_buttons">
          {#if !claimLoading}
            <button on:click={closeModal} class="btn">
              {translate('cancelclaim')}
            </button>
            <button on:click={claimBusiness} class="btn primary">
              {translate('confirmclaim')}
            </button>
          {:else}
            <i class="fas fa-spinner fa-spin" />
          {/if}
        </div>
        {#if !isEmpty(claimError)}
          <span class="text-danger">{claimError}</span>
        {/if}
      {:else}
        <i class="claim_success fas fa-check-circle fa-5x" />
        {translate('businessclaimsuccess')}
        <div class="modal_buttons">
          <button on:click={closeModal} class="ok_button btn primary">
            {translate('ok')}
          </button>
        </div>
      {/if}
    </div>
  </div>
{/if}
{#if loading}
  <div class="progress">
    <div
      class="progress-bar progress-bar-striped progress-bar-animated"
      role="progressbar"
      aria-valuenow="100"
      aria-valuemin="0"
      aria-valuemax="100"
      style="width: 100%" />
  </div>
{:else if error}
  <div>Error!</div>
{:else}
  <div class="product_wrapper">
    <div class="images">
      <ImageSlider images={product.images} />
    </div>
    <div class="wrapper">
      <div class="info_section">
        <h4>{product.name}</h4>
        <div class="product_details">{product.price}₫</div>
        <div class="product_details">
          {#if numRatings > 0}
            <div>
              <b>
                <i class="fas fa-star text-warning" />
                {avgRating}
              </b>
              <span>({numRatings})</span>
            </div>
          {/if}
        </div>
      </div>

      <div class="info_section light_weight">
        <p>{product.description}</p>
      </div>
      <div class="info_section">
        <h5>{translate('business')}</h5>
        <span
          id="claim_link"
          on:click={() => {
            showClaimBusinessModal = true;
          }}>
          {translate('claimbusiness')}
        </span>
        <div>{business.name}</div>
        <div class="light_weight">
          <div class="business_description">{business.description}</div>
          <div class="contact_section">
            {#if business.hours.length > 0}
              <strong>{translate('hours')}</strong>
              <div class="hours">
                {#each business.hours as hour}
                  <span>{translate(days[hour.day])}</span>
                  <div>
                    <span>{hour.startHour}</span>
                    -
                    <span>{hour.endHour}</span>
                  </div>
                {/each}
              </div>
            {/if}
          </div>
          <div class="contact_section">
            <strong>{translate('contactus')}</strong>
            {#if business.address}
              <div>{business.address}</div>
            {/if}
            {#if business.city}
              <div>{business.city}</div>
            {/if}
          </div>
          <div class="contact_section">
            {#if business.email}
              <div>{business.email}</div>
            {/if}
            {#if business.website}
              <a target="_blank" href={makeRootURL(business.website)}>
                {business.website}
              </a>
            {/if}
            {#if business.phoneNumber}
              <div>{business.phoneNumber}</div>
            {/if}
          </div>
          <a use:link href={`/business/${business.slug}`}>
            {translate('seeallby')} {business.name}
          </a>
        </div>
      </div>
      <div>
        <h5>{translate('reviews')}</h5>
        {#if numRatings > 0}
          <small>
            {avgRating}
            <i class="text-warning fas fa-star" />
            ({numRatings} {translate('reviews')})
          </small>
        {/if}
      </div>
      {#if !isEmpty($accessToken) && !doesUserHaveReview && !loading}
        <Link to={`/product/${id}/review`}>{translate('addreview')}</Link>
      {/if}
      <div class="reviews">
        {#each reviews as review}
          <ReviewCard {review} />
        {/each}
      </div>
    </div>
  </div>
{/if}
