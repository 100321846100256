<script>
  export let businessId;
  import { onMount } from "svelte";
  import * as yup from "yup";
  import { navigate } from "svelte-routing";
  import uuidv4 from "uuid/v4";
  import { user, instance, accessToken } from "./stores.js";
  import ImageUploader from "./components/ImageUploader.svelte";
  import { translate } from "./translations/translate.js";
  import CategoryOptions from "./components/CategoryOptions.svelte";
  import { uploadDataURLs } from "./image_util.js";

  onMount(() => {
    window.scrollTo(0, 0);
    if (!$accessToken) {
      navigate("/signin");
    }
  });
  let imageUploading = false;
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(translate("productnamerequirederror"))
      .max(100),
    description: yup.string().max(1000, translate("descriptiontoolongerror")),
    price: yup
      .number(translate("productpricerequirederror"))
      .required(translate("productpricerequirederror"))
      .positive(translate("productpricepositiveerror"))
      .max(10000000000, translate("productpricetoohigherror")),
    category: yup.string().required(translate("productcategoryrequirederror")),
    tags: yup
      .array()
      .of(yup.string().max(100))
      .max(10),
    images: yup
      .array()
      .min(1, translate("productimagerequirederror"))
      .max(5)
  });

  let validationErrors = [];
  let submitError = "";
  let newTag = "";
  let productSaving = false;
  let showTagsHelper = false;
  let showImagesHelper = false;
  let product = {
    name: "",
    description: "",
    category: "",
    price: 0,
    tags: [],
    images: []
  };

  function setCategory(category) {
    product.category = category;
  }

  function addTag(e) {
    if (e.which === 13) {
      if (!product.tags.includes(newTag)) {
        product.tags = product.tags.concat([newTag]);
        newTag = "";
      }
    }
  }
  function addTagButton() {
    if (newTag !== "") {
      product.tags = product.tags.concat([newTag]);
      newTag = "";
    }
  }

  function deleteTag(idx) {
    let newTags = [...product.tags];
    newTags.splice(idx, 1);
    product.tags = newTags;
  }

  function setAsLogo(image) {
    const newImages = [];
    for (let im of product.images) {
      im.main = im === image;
      newImages.push(im);
    }
    product.images = newImages;
  }

  function deleteImage(image) {
    const newImages = [];
    let hasNewMain = false;
    for (let i = 0; i < product.images.length; i++) {
      if (product.images[i] !== image) {
        if (image.main && !hasNewMain) {
          product.images[i].main = true;
          hasNewMain = true;
        }
        newImages.push(product.images[i]);
      }
    }
    product.images = newImages;
  }

  async function saveProduct(e) {
    validationErrors = [];
    productSaving = true;
    submitError = "";
    e.preventDefault();
    try {
      await validationSchema.validate(product, {
        abortEarly: false
      });
    } catch (err) {
      validationErrors = err.inner;
      productSaving = false;
      return;
    }

    try {
      if (product.images.length > 0) {
        const newImages = await uploadDataURLs(
          $instance,
          product.images,
          "product_images",
          product.images.length
        );
        product.images = newImages;
      }
    } catch (err) {
      productSaving = false;
      console.error(err);
    }

    try {
      const { data } = await $instance.post(
        `/businesses/${businessId}/products`,
        {
          ...product
        }
      );

      navigate(`/my/business/${businessId}/products/${data.slug}/edit`);
    } catch (err) {
      console.log(err);
      submitError = translate("servererror");
    } finally {
      productSaving = false;
    }
  }
</script>

<style>
  .tags {
    display: flex;
  }

  .clickable {
    cursor: pointer;
  }

  .tag {
    cursor: pointer;
    margin-right: 5px;
  }

  .price {
    width: 150px;
  }
</style>

<h2>{translate('createproduct')}</h2>
<div class="text-left">
  <div class="form-group">
    <label for="name">{translate('name')}</label>
    <input bind:value={product.name} class="form-control" />
    {#if validationErrors.some(e => e.path === 'name')}
      <div class="text-danger">
        {validationErrors.find(e => e.path === 'name').message}
      </div>
    {/if}
  </div>
  <div class="form-group">
    <label for="description">{translate('description')}</label>
    <textarea bind:value={product.description} class="form-control" />
    {#if validationErrors.some(e => e.path === 'description')}
      <div class="text-danger">
        {validationErrors.find(e => e.path === 'description').message}
      </div>
    {/if}
  </div>
  <div class="form-group">
    <label for="price">{translate('price')}</label>
    <div class="input-group mb-2 price">
      <input type="number" bind:value={product.price} class="form-control" />
      <div class="input-group-prepend">
        <div class="input-group-text">₫</div>
      </div>
    </div>
    {#if validationErrors.some(e => e.path === 'price')}
      <div class="text-danger">
        {validationErrors.find(e => e.path === 'price').message}
      </div>
    {/if}
  </div>
  <div class="form-group">
    <label for="category">{translate('category')}</label>
    <CategoryOptions {product} cb={setCategory} />
    {#if validationErrors.some(e => e.path === 'category')}
      <div class="text-danger">
        {validationErrors.find(e => e.path === 'category').message}
      </div>
    {/if}
  </div>
  <label>Tags</label>
  <i
    class="fas fa-question-circle clickable"
    on:click={() => (showTagsHelper = !showTagsHelper)} />
  {#if showTagsHelper}
    <small>{translate('tagshelper')}</small>
  {/if}
  <div class="input-group mb-3">
    <input
      on:keydown={addTag}
      bind:value={newTag}
      type="text"
      class="form-control"
      placeholder="{translate('newtag')}..."
      aria-label="{translate('newtag')}..."
      aria-describedby="button-addon2" />
    <div class="input-group-append">
      <button
        disabled={newTag === ''}
        class="btn primary"
        type="button"
        id="button-addon2"
        on:click={addTagButton}>
        {translate('newtag')}
      </button>
    </div>
  </div>

  <small class:text-danger={product.tags.length > 10}>
    ({product.tags.length}/10)
  </small>
  {#if product.tags}
    <div class="tags">
      {#each product.tags as tag, i}
        <span class="tag badge badge-secondary">
          {tag}
          <i
            class="fas fa-times"
            style="cursor:pointer"
            on:click={() => deleteTag(i)} />
        </span>
      {/each}
      {#if validationErrors.some(e => e.path === 'tags')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'tags').message}
        </div>
      {/if}
    </div>
  {:else}
    <div>{translate('notags')}</div>
  {/if}
  <div class="form-group">
    <label for="images">{translate('images')}</label>
    <i
      class="fas fa-question-circle clickable"
      on:click={() => (showImagesHelper = !showImagesHelper)} />
    {#if showImagesHelper}
      <small>{translate('imageshelper')}</small>
    {/if}
    <ImageUploader
      height={900}
      width={1200}
      previewHeight={100}
      fileLimit={5}
      showMainOption={true}
      loading={imageUploading}
      bind:images={product.images} />
    <small class:text-danger={product.images.length > 5}>
      ({product.images.length}/5)
    </small>
    {#if validationErrors.some(e => e.path === 'images')}
      <div class="text-danger">
        {validationErrors.find(e => e.path === 'images').message}
      </div>
    {/if}
  </div>
</div>
{#if submitError}
  <div class="text-danger">{submitError}</div>
{/if}
<button on:click={saveProduct} disabled={productSaving} class="btn primary">
  {#if productSaving}
    <i class="fas fa-spinner fa-spin" />
  {:else}{translate('save')}{/if}
</button>
