<script>
  import * as yup from "yup";
  import { onMount } from "svelte";
  import AutoComplete from "./components/AutoComplete.svelte";
  import ImageUploader from "./components/ImageUploader.svelte";
  import BusinessHours from "./components/BusinessHours.svelte";
  import cities from "./cities.json";
  import districts from "./districts.json";
  import { translate } from "./translations/translate.js";
  import { user, instance, accessToken } from "./stores.js";
  import { navigate } from "svelte-routing";
  import { uploadDataURLs } from "./image_util.js";
  import uuidv4 from "uuid/v4";
  import { isEmpty } from "lodash-es";

  let validationErrors = [];
  let isCreating = false;
  let creationError = null;
  let workflowStage = 1;
  let currentLogo = [];
  let imageUploading = false;

  const business = {
    name: "",
    description: "",
    logo: "",
    address: "",
    city: "",
    district: "",
    email: "",
    website: "",
    phone_number: ""
  };

  const getDistricts = city => districts[city] || [];

  function setCity(e) {
    business.city = e.detail;
  }
  function setDistrict(e) {
    business.district = e.detail;
  }

  async function uploadCb(result) {
    business.logo = URL.createObjectURL(result);
  }

  if (!$accessToken) {
    navigate("/");
  }
  onMount(async function() {
    const { data } = await $instance.get(`/businesses/user`);
    if (data.businesses !== null && !data.allowMultiple) {
      console.log(JSON.stringify(data));
      navigate("/my/business");
    }
  });

  yup.addMethod(yup.object, "atLeastOneOf", function(list) {
    return this.test({
      name: "atLeastOneOf",
      message: "Your business must have at least one contact method",
      exclusive: true,
      path: "form",
      params: { keys: (list || []).join(", ") },
      test: value => (list || []).some(f => value[f] != "")
    });
  });

  const workflowStage1ValidationSchema = yup.object().shape({
    name: yup
      .string()
      .required()
      .max(100),
    description: yup.string().max(1000)
  });

  const workflowStage2ValidationSchema = yup
    .object()
    .shape({
      address: yup.string().max(200),
      email: yup.string().email(),
      website: yup.string().max(1000),
      city: yup.string().max(200),
      district: yup.string().max(200),
      phone_number: yup.string().max(30)
    })
    .atLeastOneOf(["address", "phone_number", "email", "website"]);

  async function nextStep() {
    validationErrors = [];
    try {
      await workflowStage1ValidationSchema.validate(business, {
        abortEarly: false
      });
    } catch (err) {
      validationErrors = err.inner;
      return;
    }
    workflowStage += 1;
  }

  function previousStep() {
    workflowStage -= 1;
  }

  async function createBusiness() {
    validationErrors = [];
    creationError = null;
    isCreating = true;
    try {
      await workflowStage2ValidationSchema.validate(business, {
        abortEarly: false
      });
    } catch (err) {
      console.error(err);
      validationErrors = err.inner;
      isCreating = false;
      creationError = translate("servererror");
      return;
    }
    try {
      if (currentLogo.length > 0 && currentLogo[0].url.startsWith("data:")) {
        const urls = await uploadDataURLs(
          $instance,
          [currentLogo[0]],
          "business_logos"
        );
        business.logo = urls[0].url;
      }
      const { data } = await $instance.post("/businesses", business);
      navigate(`/my/business/${data.slug}`);
    } catch (err) {
      isCreating = false;
      creationError = translate("servererror");
      console.error(err);
    }
  }
</script>

<style>
  .form {
    text-align: start;
  }

  .logo_wrapper {
    display: grid;
    grid-template-columns: 100px 100px;
    grid-gap: 15px;
  }
</style>

<h1>Create New Business</h1>
{#if workflowStage === 1}
  <small>
    Let's get started! First give your business a name, and optionally, a
    description and logo.
  </small>
  <div class="form">
    <div class="form-group">
      <label for="name">{translate('name')}</label>
      <input name="name" bind:value={business.name} class="form-control" />
      {#if validationErrors.some(e => e.path === 'name')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'name').message}
        </div>
      {/if}
    </div>
    <div class="form-group">
      <label for="description">
        {translate('description')}
        <small>({translate('optional')})</small>
      </label>
      <textarea
        name="description"
        bind:value={business.description}
        class="form-control" />
      <small class:text-danger={business.description.length > 1000}>
        ({business.description.length}/1000)
      </small>
      {#if validationErrors.some(e => e.path === 'description')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'description').message}
        </div>
      {/if}
    </div>
    <div class="logo_wrapper">
      <div class="form-group">
        <label for="logo">
          {translate('logo')}
          <small>({translate('optional')})</small>
        </label>
        <ImageUploader
          aspectRatio={1}
          height={100}
          width={100}
          loading={imageUploading}
          completionCb={uploadCb}
          bind:images={currentLogo} />
      </div>
      {#if validationErrors.some(e => e.path === 'logo')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'logo').message}
        </div>
      {/if}
    </div>
  </div>
  <button class="btn primary" on:click={nextStep}>Next</button>
{:else}
  <small>
    Almost done! Just add some contact information for your business.
  </small>
  <div class="form">
    <div class="form-group">
      <label for="address">{translate('address')}</label>
      <input
        name="address"
        bind:value={business.address}
        class="form-control" />
      {#if validationErrors.some(e => e.path === 'address')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'address').message}
        </div>
      {/if}
    </div>
    <div class="form-group">
      <label for="city">{translate('city')}</label>
      <AutoComplete
        search={business.city}
        name="city"
        items={cities}
        on:close={setCity}>
        <div class="notification">Loading data from API...</div>
      </AutoComplete>
      {#if validationErrors.some(e => e.path === 'city')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'city').message}
        </div>
      {/if}
    </div>
    {#if !isEmpty(getDistricts(business.city))}
      <div class="form-group">
        <label for="district">{translate('district')}</label>
        <select bind:value={business.district}>
          {#each getDistricts(business.city).sort() as district}
            <option value={district}>{district}</option>
          {/each}
        </select>
        {#if validationErrors.some(e => e.path === 'district')}
          <div class="text-danger">
            {validationErrors.find(e => e.path === 'district').message}
          </div>
        {/if}
      </div>
    {/if}
    <div class="form-group">
      <label for="email">{translate('email')}</label>
      <input name="email" bind:value={business.email} class="form-control" />
      {#if validationErrors.some(e => e.path === 'email')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'email').message}
        </div>
      {/if}
    </div>
    <div class="form-group">
      <label for="website">{translate('website')}</label>
      <input
        name="website"
        bind:value={business.website}
        class="form-control" />
      {#if validationErrors.some(e => e.path === 'website')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'website').message}
        </div>
      {/if}
    </div>
    <div class="form-group">
      <label for="phone_number">{translate('phonenumber')}</label>
      <input
        name="phone_number"
        bind:value={business.phone_number}
        class="form-control" />
      {#if validationErrors.some(e => e.path === 'phone_number')}
        <div class="text-danger">
          {validationErrors.find(e => e.path === 'phone_number').message}
        </div>
      {/if}
    </div>
    <div class="form-group">
      <label for="hours">{translate('hours')}</label>
      <BusinessHours bind:hours={business.hours} />
    </div>
  </div>
  <div>
    {#if validationErrors.some(e => e.path === undefined)}
      <div class="text-danger">
        {validationErrors.find(e => e.path === undefined).message}
      </div>
    {/if}
    {#if creationError}
      <div class="text-danger">{creationError}</div>
    {/if}
    <button class="btn btn-secondary" on:click={previousStep}>Previous</button>
    <button class="btn primary" on:click={createBusiness}>
      {#if !isCreating}
        Create
      {:else}
        <i class="fas fa-spinner fa-spin" />
      {/if}
    </button>
  </div>
{/if}
