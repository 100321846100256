<script>
  import { createEventDispatcher, beforeUpdate } from "svelte";
  const dispatch = createEventDispatcher();
  import { debounce } from "lodash-es";

  const regExpEscape = s => {
    return s.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
  };

  export let name = "";
  export let value = "";
  let key = "";
  export let placeholder = "";
  export let required = false;
  export let disabled = false;

  // autocomplete props
  export let items = [];
  export let isOpen = false;
  let results = [];
  export let search = "";
  export let isLoading = false;
  export let arrowCounter = 0;
  export let isAsync = false;
  let className = "";
  let minChar = 2;
  let maxItems = 10;
  let fromStart = true; // Default type ahead
  let list;
  let input;

  //   $: if (items.length > 0) {
  //     filterResults();
  //     isOpen = true;
  //   }

  function nonAccentVietnamese(str) {
    str = str.toLowerCase();
    //     We can also use this instead of from line 11 to line 17
    //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
    //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
    //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
    //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
    //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
    //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
    //     str = str.replace(/\u0111/g, "d");
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
  }

  const submitSearch = event => {
    isLoading = false;
    filterResults();
  };
  async function onChange(event) {
    // Is the data given by an outside ajax request?
    if (isAsync) {
      isLoading = true;
    } else if (search.length >= Number(minChar)) {
      filterResults();
      isOpen = true;
    }
  }
  function filterResults() {
    results = items
      .filter(item => {
        if (typeof item !== "string") {
          item = item.key || ""; // Silent fail
        }
        return fromStart
          ? nonAccentVietnamese(item)
              .toUpperCase()
              .startsWith(nonAccentVietnamese(search).toUpperCase())
          : nonAccentVietnamese(item)
              .toUpperCase()
              .includes(nonAccentVietnamese(search).toUpperCase());
      })
      .map(item => {
        const text = typeof item !== "string" ? item.key : item;
        return {
          key: text,
          value: item.value || item,
          label:
            search.trim() === ""
              ? text
              : text.replace(
                  RegExp(regExpEscape(search.trim()), "i"),
                  "<span>$&</span>"
                )
        };
      });

    const height = results.length > maxItems ? maxItems : results.length;
    list.style.height = `${height * 2.25}rem`;
  }
  function onKeyDown(event) {
    if (event.keyCode === 40 && arrowCounter < results.length) {
      // ArrowDown
      arrowCounter = arrowCounter + 1;
    } else if (event.keyCode === 38 && arrowCounter > 0) {
      // ArrowUp
      arrowCounter = arrowCounter - 1;
    } else if (event.keyCode === 13) {
      // Enter
      event.preventDefault();
      if (arrowCounter === -1) {
        arrowCounter = 0; // Default select first item of list
      }
      close(arrowCounter);
    } else if (event.keyCode === 27) {
      // Escape
      event.preventDefault();
      close();
    }
  }
  function close(index = -1) {
    isOpen = false;
    arrowCounter = -1;
    input.blur();
    if (index > -1) {
      dispatch("close", results[index].value);
      value = results[index].value;
      key = results[index].key;
    } else if (!value) {
      search = "";
    }
  }
</script>

<style>
  * {
    box-sizing: border-box;
  }

  input {
    height: 2rem;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
  }

  .autocomplete {
    position: relative;
  }

  .hide-results {
    display: none;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #dbdbdb;
    height: 6rem;
    overflow: auto;
    width: 100%;

    background-color: white;
    box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 100;
  }

  .autocomplete-result {
    color: #7a7a7a;
    list-style: none;
    text-align: left;
    height: 2rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
  }

  .autocomplete-result > :global(span) {
    background-color: none;
    color: #242424;
    font-weight: bold;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #dbdbdb;
  }
</style>

<svelte:window on:click={() => close()} />
<div on:click={event => event.stopPropagation()} class="autocomplete">
  <input
    type="text"
    class={className}
    {name}
    {placeholder}
    {required}
    {disabled}
    value={value || ''}
    autocomplete={name}
    bind:value={search}
    on:input={onChange}
    on:focus
    on:blur
    on:keydown={event => onKeyDown(event)}
    bind:this={input} />
  <ul
    class="autocomplete-results{!isOpen ? ' hide-results' : ''}"
    bind:this={list}>
    {#each results as result, i}
      <li
        on:click={() => close(i)}
        class="autocomplete-result{i === arrowCounter ? ' is-active' : ''}">
        {@html result.label}
      </li>
    {/each}
  </ul>
  {#if isLoading}
    <slot>
      <p class="fallback">Loading data...</p>
    </slot>
  {/if}
</div>
